<template>
  <section>
    <folha v-for="(elementos, indexFolha) in tamanhoElemento" :key="indexFolha">
      <br /><br />
      <h3 v-if="via == '2'">2ª VIA DO LAUDO</h3>
      <br /><br />

      <div v-if="indexFolha === 0">
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <!-- <h3><strong>CONVÊNIO:</strong> {{ convenio.nome }}</h3> -->
          <br />
        </div>

        <br />
        <br />
        <h3>
          ULTRASSONOGRAFIA PÉLVICA TRANSVAGINAL COM DOPPLER PARA PESQUISA DE
          ENDOMETRIOSE PROFUNDA
        </h3>
        <br />
        <br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          {{ elemento }}
          <p></p>
        </h4>
        <div v-else>
          {{ elemento }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha + 1 == tamanhoElemento.length">
        <span
          ><strong
            >Dra
            {{
              medico.nome == 'Adriana Melo (curso)'
                ? 'Adriana Melo'
                : medico.nome
            }}</strong
          > </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
import moment from 'moment';

export default {
  mixins: [ImprecoesMixins],

  mounted() {
    if (this.$route.query.via) this.via = this.$route.query.via;
    let folha = [];

    if (this.laudo.dataDum) folha.push(`DUM: ${this.laudo.dataDum}.`);

    if (this.laudo.diaCicloMenstrual)
      folha.push(`${this.laudo.diaCicloMenstrual}º dia do ciclo menstrual.`);

    if (this.laudo.uteroPosicao)
      folha.push(`${this.laudo.uteroPosicao} uso de contraceptivos hormonais.`);

    if (this.laudo.uretra)
      folha.push(
        `Urétra: ${this.laudo.uretra}${
          this.laudo.uretra == 'outro' ? this.laudo.uretraOutros : ''
        } ${this.laudo.uretra2}${
          this.laudo.uretra2 == 'outro' ? this.laudo.uretra2Outros : ''
        }.`
      );

    if (this.laudo.bexigaCheia1)
      folha.push(
        `Bexiga: cheia, ${this.laudo.bexigaCheia1}${
          this.laudo.bexigaCheia1 == 'outro'
            ? this.laudo.bexigaCheia1Outros
            : ''
        } e ${this.laudo.bexigaCheia2}${
          this.laudo.bexigaCheia2 == 'outro'
            ? this.laudo.bexigaCheia2Outros
            : ''
        } com conteúdo ${this.laudo.bexigaCheia3}${
          this.laudo.bexigaCheia3 == 'outro'
            ? this.laudo.bexigaChei32Outros
            : ''
        }.`
      );

    if (this.laudo.vagina1)
      folha.push(
        `Vagina: ${this.laudo.vagina1}${
          this.laudo.vagina1 == 'outro' ? this.laudo.vagina1Outros : ''
        }, ${this.laudo.vagina2}${
          this.laudo.vagina2 == 'outro' ? this.laudo.vagina2Outros : ''
        }.`
      );

    if (this.laudo.coloUterino1)
      folha.push(
        `Colo uterino ${this.laudo.coloUterino1}${
          this.laudo.coloUterino1 == 'outro'
            ? this.laudo.coloUterino1Outros
            : ''
        }, ${this.laudo.coloUterino2}${
          this.laudo.coloUterino2 == 'outro'
            ? this.laudo.coloUterino2Outros
            : ''
        } e canal endocervical ${this.laudo.canalEndocervical}${
          this.laudo.canalEndocervical == 'outro'
            ? this.laudo.canalEndocervicalOutros
            : ''
        }.`
      );

    if (this.laudo.corpoUterino1)
      folha.push(
        `Corpo uterino: ${this.laudo.corpoUterino1}${
          this.laudo.corpoUterino1 == 'outro'
            ? this.laudo.corpoUterino1Outros
            : ''
        } em ${this.laudo.corpoUterino2}${
          this.laudo.corpoUterino2 == 'outro'
            ? this.laudo.corpoUterino2Outros
            : ''
        }.`
      );

    if (this.laudo.formaColoUterino)
      folha.push(
        `A forma é ${this.laudo.formaColoUterino}${
          this.laudo.formaColoUterino == 'outro'
            ? this.laudo.formaColoUterinoOutros
            : ''
        } e os contornos ${this.laudo.contornoColoUterino}${
          this.laudo.contornoColoUterino == 'outro'
            ? this.laudo.contornoColoUterinoOutro
            : ''
        }.`
      );

    if (this.laudo.paredeColoUterino)
      folha.push(
        `As paredes são ${this.laudo.paredeColoUterino}${
          this.laudo.paredeColoUterino == 'outro'
            ? this.laudo.paredeColoUterinoOutros
            : ''
        } e o miométrio apresenta zona juncional ${this.laudo.zonaJuncional}${
          this.laudo.zonaJuncional == 'outro'
            ? this.laudo.zonaJuncionalOutros
            : ''
        }, com textura miometrial ${this.laudo.texturaMiometrial}${
          this.laudo.texturaMiometrial == 'outro'
            ? this.laudo.texturaMiometrialOutros
            : ''
        }.`
      );

    if (folha.length > 0) folha.splice(0, 0, '');
    this.dumz = [...folha];
    folha = [];

    if (this.laudo.biometriaUterinaUtero1)
      folha.push(
        `Útero: ${this.laudo.biometriaUterinaUtero1} x ${this.laudo.biometriaUterinaUtero2} x ${this.laudo.biometriaUterinaUtero3} cm e volume de ${this.laudo.biometriaUterinaUtero4}cm³.`
      );

    if (this.laudo.fundoCm) folha.push(`Fundo: ${this.laudo.fundoCm} cm.`);

    if (this.laudo.cavidadeUterinaCm)
      folha.push(`Cavidade uterina: ${this.laudo.cavidadeUterinaCm} cm.`);

    if (this.laudo.coloCm) folha.push(`Colo: ${this.laudo.coloCm} cm.`);

    if (this.laudo.ecoEndometrial)
      folha.push(
        `O eco endometrial é ${this.laudo.ecoEndometrial}${
          this.laudo.ecoEndometrial == 'outro'
            ? this.laudo.ecoEndometrialOutros
            : ''
        }, ${this.laudo.ecoEndometrial2}${
          this.laudo.ecoEndometrial2 == 'outro'
            ? this.laudo.ecoEndometrial2Outros2
            : ''
        } ,com linha média ${this.laudo.linhaMedia}${
          this.laudo.linhaMedia == 'outro' ? this.laudo.linhaMediaOutros : ''
        } e junção endométrio-miométrio ${this.laudo.contornoColoUterino2}.`
      );

    if (this.laudo.espessuraEndometrialMm)
      folha.push(
        `Espessura endometrial de ${this.laudo.espessuraEndometrialMm}mm.`
      );

    if (
      this.laudo.cavidadeUterina &&
      this.laudo.cavidadeUterina != 'Imagem hiperecóica medindo' &&
      this.laudo.cavidadeUterina != 'outro'
    )
      folha.push(`Cavidade uterina: ${this.laudo.cavidadeUterina}.`);

    if (
      this.laudo.cavidadeUterina &&
      this.laudo.cavidadeUterina == 'Imagem hiperecóica medindo'
    )
      folha.push(
        ` Cavidade uterina: ${this.laudo.cavidadeUterina} ${this.laudo.comprimentoCabecaNadegas} cm, apresentando pedículo vascular.`
      );

    if (this.laudo.cavidadeUterina && this.laudo.cavidadeUterina == 'outro')
      folha.push(
        ` Cavidade uterina: ${this.laudo.ecoEndometrial2Outros} cm, apresentando pedículo vascular`
      );

    if (this.laudo.ovarioDireito1) folha.push('Ovários');
    if (this.laudo.ovarioDireito1)
      folha.push(
        `Ovário direito: ${this.laudo.ovarioDireito1}${
          this.laudo.ovarioDireito1 == 'outro'
            ? this.laudo.ovarioDireito1Outro
            : ''
        }, ${this.laudo.ovarioDireito2Outro}${
          this.laudo.ovarioDireito2Outro == 'outro'
            ? this.laudo.ovarioDireito3Outro
            : ''
        }.`
      );

    if (this.laudo.imagemAnecoica !== 'outro')
      folha.push(`${this.laudo.imagemAnecoica}.`);
    if (this.laudo.imagemAnecoica === 'outro')
      folha.push(`${this.laudo.imagemHeterogeneaOutro}.`);

    if (
      this.laudo.imagemAnecoica ===
      'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
    )
      folha.push(`${this.laudo.imagemAnecoicaCm} cm no seu maior diâmetro.`);

    if (
      this.laudo.imagemAnecoica ===
      'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
    )
      folha.push(`${this.laudo.finoSeptoCm} cm no seu maior diâmetro.`);

    if (
      this.laudo.imagemAnecoica ===
      'Apresenta imagem anecóica com debris, medindo'
    )
      folha.push(`${this.laudo.anecoicaDebrisCm} cm no seu maior diâmetro.`);

    if (
      this.laudo.imagemAnecoica ===
      'Apresenta imagem anecóica multiseptada medindo'
    )
      folha.push(
        `${this.laudo.anecoicaMultiseptadaCm} cm no seu maior diâmetro.`
      );

    if (this.laudo.imagemAnecoica === 'Apresenta imagem heterogênea medindo')
      folha.push(
        `${this.laudo.anecoicaMultiseptadaCm2} x ${this.laudo.imagemHeterogeneaCm} cm.`
      );

    if (this.laudo.medidasOvarioDireito1)
      folha.push(
        `Medidas do ovário direito: ${this.laudo.medidasOvarioDireito1} x ${this.laudo.medidasOvarioDireito2} x ${this.laudo.medidasOvarioDireito3} cm e volume de ${this.laudo.volumeOvarioDireito} cm³.`
      );

    if (this.laudo.ovarioEsquerdo)
      folha.push(
        `Ovário esquerdo: ${this.laudo.ovarioEsquerdo}${
          this.laudo.ovarioEsquerdo == 'outro'
            ? this.laudo.ovarioEsquerdoOutros
            : ''
        }, ${this.laudo.ovarioEsquerdo2}${
          this.laudo.ovarioEsquerdo2 == 'outro'
            ? this.laudo.ovarioEsquerdoOutros2
            : ''
        }.`
      );

    if (this.laudo.imagemAnecoica2)
      folha.push(
        `${
          this.laudo.imagemAnecoica2 !== 'outro'
            ? this.laudo.imagemAnecoica2
            : ''
        }${
          this.laudo.imagemAnecoica2 == 'outro'
            ? this.laudo.imagemHeterogeneaOutro2
            : ''
        }a.`
      );

    // if (this.laudo.imagemAnecoica2)
    //   folha.push(`${this.laudo.imagemAnecoica2}.`);

    if (
      this.laudo.imagemAnecoica2 ===
      'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
    )
      folha.push(`${this.laudo.imagemAnecoicaCm2} cm no seu maior diâmetro.`);

    if (
      this.laudo.imagemAnecoica2 ===
      'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
    )
      folha.push(`${this.laudo.finoSeptoCm2} cm no seu maior diâmetro.`);

    if (
      this.laudo.imagemAnecoica2 ===
      'Apresenta imagem anecóica com debris, medindo'
    )
      folha.push(`${this.laudo.anecoicaDebrisCm2} cm no seu maior diâmetro.`);

    if (
      this.laudo.imagemAnecoica2 ===
      'Apresenta imagem anecóica multiseptada medindo'
    )
      folha.push(
        `${this.laudo.anecoicaMultiseptadaCm21} cm no seu maior diâmetro.`
      );

    if (this.laudo.imagemAnecoica2 === 'Apresenta imagem heterogênea medindo')
      folha.push(
        `${this.laudo.anecoicaMultiseptadaCm22} x ${this.laudo.imagemHeterogeneaCm2} cm.`
      );

    // if (this.laudo.imagemAnecoica2 === 'outro')
    //   folha.push(`${this.laudo.imagemHeterogeneaOutro2}`);

    if (this.laudo.medidasOvarioEsquerdo1)
      folha.push(
        `Medidas do ovário esquerdo: ${this.laudo.medidasOvarioEsquerdo1} x ${this.laudo.medidasOvarioEsquerdo2} x ${this.laudo.medidasOvarioEsquerdo3} cm e volume de ${this.laudo.volumeOvarioEsquerdo} cm³.`
      );

    if (this.laudo.sinaisEndometriose)
      folha.push(
        `${this.laudo.sinaisEndometriose}${
          this.laudo.sinaisEndometriose == 'outro'
            ? this.laudo.sinaisEndometrioseOutro
            : ''
        }.`
      );

    if (this.laudo.sinaisEndometriose2)
      folha.push(
        `${this.laudo.sinaisEndometriose2}${
          this.laudo.sinaisEndometriose2 == 'outro'
            ? this.laudo.sinaisEndometrioseOutro2
            : ''
        }.`
      );

    if (this.laudo.RinsAspecto)
      folha.push(
        `${this.laudo.RinsAspecto}${
          this.laudo.RinsAspecto == 'outro' ? this.laudo.RinsAspectoOutro : ''
        }.`
      );

    if (folha.length > 0) folha.splice(0, 0, 'Biometria uterina');
    this.biometriauterinaz = [...folha];
    folha = [];

    if (this.laudo.conclusao1) folha.push(`${this.laudo.conclusao1}.`);

    if (this.laudo.conclusao2) folha.push(`${this.laudo.conclusao2}.`);

    if (this.laudo.comentariosAdcionais)
      folha.push(`${this.laudo.comentariosAdcionais}.`);
    if (folha.length > 0) folha.splice(0, 0, 'Conclusão');
    this.conclusaoz = [...folha];
    folha = [];

    const todos = [...this.dumz, ...this.biometriauterinaz, ...this.conclusaoz];

    while (todos.length > 0) {
      this.tamanhoElemento.push(todos.splice(0, 29));
    }
  },

  data() {
    return {
      dumz: [],
      biometriauterinaz: [],
      conclusaoz: [],
      via: '',
      tamanhoElemento: [],
      titulos: ['DUM', 'Biometria uterina', 'Conclusão', 'Ovários'],
    };
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }

  h4 {
    margin: 0px;
    margin-top: 0px;
    line-height: 5px;
  }

  p {
    text-align: justify;
    font-family: 'Calibri';
    margin-left: -5px;

    margin-top: 5px;
    line-height: 6px;
    padding: 5px;
  }

  br {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    line-height: 100%;
  }

  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    line-height: 5px;
  }

  footer {
    //position: absolute;
    bottom: 0;
    margin-left: 0px;

    width: 80%;
    height: 100px;
    text-align: left;
    line-height: 20px;
  }
}
</style>
